header {
  background-color: $white;
  font-size: 16px;
  box-shadow:0 1px 3px 0 rgba(0,0,0,0.25);
  margin-bottom: $spacer;
  z-index: 9999;
  .logo {
    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;
  }
  .navbar-brand {
    p {
      color: $black !important;
    }
    a, a:hover {
      color: $black !important;
      text-decoration: none;
      font-weight: 600;
    }
  }
}
@include media-breakpoint-down(lg) {
  header {
    position: fixed;
    right: 0;
    left: 0;
    border-radius: 0;
    top: 0;
  }
  body {
    margin-top: 5.75rem + $spacer;
  }
}