section.date {
  background-color: $white;
  box-shadow: $boxShadow;
  border-radius:$borderRadius;
  margin-bottom: $spacer/2;
  .headline {
    padding-left: $box-padding;
    padding-right: $box-padding;
    padding-top: $box-padding;
    border-bottom: 1px solid $hr-color;
    @include media-breakpoint-down(sm) {
      padding-left: $box-padding/2;
    }
    h3 {
      margin: 0;
      padding-bottom: 8px;
    }
  }
  .content {
    padding-top: $box-padding / 2;
    padding-left: $box-padding;
    padding-right: $box-padding;
    padding-bottom: $box-padding;
    @include media-breakpoint-down(sm) {
      padding-top: $box-padding / 4;
      padding-left: $box-padding / 2;
      padding-right: $box-padding / 2;
      padding-bottom: $box-padding / 2;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
    img {
      padding-top: $spacer/2;
    }
  }
}