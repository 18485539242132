blockquote.blockquote {
  background-color: $white;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
  padding: $box-padding;
  img {
    margin-bottom: $spacer/2;
  }
  p {
    margin-bottom: 0;
  }
}