section.txt {
  background-color: $white;
  box-shadow: $boxShadow;
  border-radius:$borderRadius;
  margin-bottom: $spacer/2;
  .headline {
    padding-top: $box-padding;
    border-bottom: 1px solid $hr-color;
    @include media-breakpoint-down(sm) {
      padding-top: $box-padding/3;
    }
    h3 {
      display: inline-block;
      margin: 0;
      padding-bottom: 8px;
      padding-left: 20px;
      @include media-breakpoint-down(sm) {
        padding-left: 10px;
        width: 80%;
      }

    }
    img {
      display: inline-block;
      padding-left: $box-padding;
      @include media-breakpoint-down(sm) {
        padding-left: $box-padding/3;
      }
    }
  }
  .content {
    padding: $box-padding;
    @include media-breakpoint-down(sm) {
      padding: $box-padding/3;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
    ul {
      padding-left: 30px;
      margin-bottom: $spacer/2;
      list-style-type: none;
      li {
        position: relative;
      }
      li:before {
        content: "·";
        font-size: 40px;
        vertical-align: middle;
        line-height: 0;
        position: absolute;
        top: 15px;
        left: -30px;
      }
    }
  }



}