footer {
  margin-top: $spacer;
  background-color: $darkred;
  position: relative;
  img {
    position: absolute;
    right: 0;
    bottom: 0;
    @media (max-width: 1249px) {
      display: none;
    }
  }
  p, a, a:hover {
    color: $white;
  }
  p {
    margin-bottom: 0;
  }
  .container {
    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;
    margin-bottom: 0;
    .row {
      @include media-breakpoint-down(md) {
        .col-lg-3.col-md-6:nth-child(1), .col-lg-3.col-md-6:nth-child(2) {
          margin-bottom: $spacer/2;
        }
      }
      @include media-breakpoint-down(sm) {
        .col-lg-3.col-md-6:nth-child(3) {
          margin-bottom: $spacer/2;
        }
      }
    }
  }

}