nav {
  .container.withpadding {
    padding: 0 $grid-gutter-width/2;
  }
  .navbar-brand {
    padding: $spacer/2 0;
    font-size: 16px;
    font-weight: 600;
    img {
      width: 161px;
    }
    p {
      margin: 0;
    }
  }
  &.navbar-expand-xl {
    flex-flow: wrap;
    display: block;
  }
  &.navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    flex-flow: wrap;
  }
  .navbar-nav {
    a, a:hover {
      font-weight: 600;
      color: $black;
      text-transform: uppercase;
    }
    a.active {
      border-bottom: 3px solid $darkred;
    }

    a.nav-link {
      display: inline-block;
      padding: 0.5rem 0;
      margin: 0 1rem;
    }
    li:first-of-type {
      a.nav-link {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
  ul.navbar-nav.nav-fill {
    width: 100%;
  }

  button.navbar-toggler.collapsed {
    span:nth-child(2) {
      left: 0;
      opacity: 1;
    }
    span:nth-child(1), span:nth-child(3) {
      transform: rotate(0deg);
      top: 0;
    }
  }
  button.navbar-toggler {
    border: none;

    span {
      width: 26px;
      height: 2px;
      background-color: $black;
      display: block;
      transition: all 300ms, opacity 150ms;
      position: relative;
    }
    span:nth-child(1), span:nth-child(2) {
      margin-bottom: 4px;
    }
    span:nth-child(1) {
      transform: rotate(45deg);
      top: 6px;
    }
    span:nth-child(2) {
      left: -10px;
      opacity: 0;
    }
    span:nth-child(3) {
      transform: rotate(-45deg);
      top: -6px;
    }
  }
}

@include media-breakpoint-down(lg) {
  nav {
    .container.navcontainer {
      max-width: 100% !important;
    }
    .navbar-collapse {
      background-color: $darkred;

      ul.navbar-nav {
        box-shadow:0 2px 4px 0 rgba(0,0,0,0.50);
        li.nav-item {
          margin: 0 $grid-gutter-width/2;
          text-align: center !important;
          border-bottom: 1px solid $white;
          a.nav-link, a.nav-link:hover {
            color: $white;
            font-weight: 300;
            padding: $spacer/2 0;
          }
          a.active {
            border-bottom: 0;
          }
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.mobilenavbg {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: zIndex 0ms, opacity 150ms;
  &.active {
    opacity:0.54;
    z-index: 9998;
    background:#000000;
  }
}