// MODELS

$box-padding: 35px;
$hr-color: #e6e6e6;
$borderRadius: 8px;
$boxShadow: 0 2px 4px 0 rgba(0,0,0,0.25);

@import "modules/bootstrap/bootstrap";
@import "modules/fonts";
@import "modules/blockquote";
@import "modules/nav";
@import "modules/header";
@import "modules/footer";
@import "modules/section.txt";
@import "modules/section.date";
@import "modules/img.block";
@import "modules/partner";

a {
  font-weight: 400;

}

b,
strong {
  font-weight: $font-weight-bold;
}

button:focus {
  outline: none !important;
}

h1, h2 {
  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
}
h2 {
  margin-top: $spacer;
  margin-bottom: $spacer/2;
}

h3 {
  color: $black !important;
  @include media-breakpoint-down(sm) {
    font-size: 19px;
  }

}

h4 {
  color: $black !important;
  margin-bottom: $spacer/2 !important;
}

.noPadBot {
  padding-bottom: 0 !important;
}
.noMarBot {
  margin-bottom: 0 !important;
}
.marBot {
 margin-bottom: $spacer;
}
.displayBlock {
  display: block;
  margin-bottom: $spacer/2;
}
.container {
  margin-bottom: $spacer/2;
}
.container.minusMarg {
  margin-bottom: -$spacer;
}
header {
  .container {
    margin-bottom: 0;
  }
}
.img-fluid {
  width: 100%;
}

.noWidth {
  width: auto !important;
}


img.signature {
  max-width: 100%;
}